import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { errorToast } from 'app/utils/toast';

import {
  SaveWaferTableStructure,
  setImageFit,
  saveLotTableStructure,
  saveFileSetTableStructure
} from 'store/helpers/actions';
import { SaveTableStructure } from 'store/dataLibrary/actions';
import { setIsUrOrEnabled } from 'store/common/actions';

import api from 'app/api';
import store from 'store';

const useMetaFiltersLoad = () => {
  const { subscriptionId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (subscriptionId) {
      api
        .getSubscription(subscriptionId)
        .then(res => {
          if (res.feature_flags) {
            dispatch(
              setIsUrOrEnabled(res.feature_flags.is_ur_or_enabled ?? false)
            );
          }

          if (res.image_fit !== store.getState().helpers.imageFit) {
            dispatch(setImageFit(res.image_fit));
          }

          dispatch(SaveWaferTableStructure(res.wafer_map_meta_info));
          dispatch(saveLotTableStructure(res.lot_meta_info));
          dispatch(saveFileSetTableStructure(res.file_set_meta_info));

          if (res.file_set_meta_info?.length) {
            dispatch(SaveTableStructure(res.file_set_meta_info));
          }
        })
        .catch(() => {
          errorToast(`Failed to load meta filters.`);
        });
    }
  }, [subscriptionId]);

  return null;
};

export default useMetaFiltersLoad;
