import type {
  createUseCaseType,
  UpdateAutomationConditionPayload,
  updateUseCaseType,
  Usecase,
  UsecaseFetch,
  UsecaseFetchAll,
  UsecaseResponse
} from 'app/api/Usecase/types';

import { apiCallBack, formatApiIdkey } from '../ApiMethods';
import { ENDPOINTS_CONSTANTS } from '../endpoints';

const { endpoint, version } = ENDPOINTS_CONSTANTS.USECASE;

const API_ENDPOINT = `/api/${version}/classif-ai/${endpoint}/`;

const API_ENDPOINT_V2 = `/api/v2/classif-ai/${endpoint}`;

export const getUseCases = (
  params: ApiBase<UsecaseFetch | UsecaseFetchAll>
) => {
  const useV2 = !!params?.baseParams?.useV2;

  delete params?.baseParams?.useV2;

  return apiCallBack({
    method: 'GET',
    url: useV2 ? API_ENDPOINT_V2 : API_ENDPOINT,
    ...params,
    transformParams: (baseParams, locationParams) => {
      const updatedLocationParams = locationParams
        ? formatApiIdkey({
            locationParams,
            apiKey: 'use_case'
          })
        : {};

      const finalParams = { ...(baseParams ?? {}), ...updatedLocationParams };

      if ('search' in finalParams) {
        if ((finalParams.search as string).length > 0) {
          finalParams['name__icontains' as keyof typeof finalParams] =
            finalParams.search;
        }

        delete finalParams.search;
      }

      return finalParams;
    }
  }).then(_ => _.data as UsecaseResponse<Usecase>);
};

export const getUseCaseById = (params: ApiBase<FetchById>) => {
  const { id } = params.baseParams;

  return apiCallBack({
    method: 'GET',
    url: `${API_ENDPOINT}${id}/`
  }).then(_ => _.data as Usecase);
};

export const createUseCase = (params: { data: createUseCaseType }) => {
  const { data } = params;

  return apiCallBack({
    method: 'POST',
    url: API_ENDPOINT,
    data
  }).then(_ => _.data);
};

export const updateUseCase = (params: updateUseCaseType) => {
  const { id, data } = params;

  return apiCallBack({
    method: 'PATCH',
    url: `${API_ENDPOINT}${id}/`,
    data
  }).then(_ => _.data);
};

export const getDefectOrderPriority = (params: ApiBase<FetchById>) => {
  const { id } = params.baseParams;

  return apiCallBack({
    method: 'GET',
    url: `${API_ENDPOINT}${id}/defect_priority_order/`
  }).then(_ => _.data);
};

export const updateAutomationConditions = (
  data: UpdateAutomationConditionPayload
) => {
  return apiCallBack({
    method: 'PATCH',
    url: `${API_ENDPOINT}update-automation-accuracy-thresholds/`,
    data
  }).then(_ => _.data);
};
