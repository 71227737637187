import type { AxiosPromise, AxiosResponse, Method, ResponseType } from 'axios';

import { axiosInstance } from '../axiosConfig';
import {
  converObjArraytoString,
  getParamsObjFromEncodedString
} from 'app/utils/helpers';

type MethodWithoutGet = Exclude<Method, 'GET' | 'get'>;

export type ApiParams<T, U> = Partial<ApiBase<T>> & {
  url: string;
  transformParams?: (baseParams?: T, locationParams?: object) => object;
  responseType?: ResponseType;
} & (
    | { method: Extract<Method, 'GET' | 'get'>; data?: undefined }
    | { method: MethodWithoutGet; data?: U }
  );

export const apiCallBack = <T, U, K>(
  apiParams: ApiParams<T, U>
): AxiosPromise<K> => {
  const {
    method,
    url,
    data,
    transformParams,
    signal,
    locationParams,
    baseParams,
    responseType
  } = apiParams;

  const finalParams = transformParams
    ? transformParams(baseParams, locationParams)
    : { ...(baseParams ?? {}), ...(locationParams ?? {}) };

  return axiosInstance({
    method,
    url,
    data,
    params: finalParams,
    signal,
    responseType
  });
};

export const formatApiIdkey = (params: {
  locationParams: object;
  apiKey: string;
}): object => {
  const { locationParams, apiKey } = params;

  const key = `${apiKey}_id__in` as keyof typeof locationParams;

  if (locationParams[key]) {
    locationParams['id__in' as keyof typeof locationParams] =
      locationParams[key];

    delete locationParams[key];
  }

  return locationParams;
};

export const formatParams = (
  apiId: string,
  allowedKeys: Array<string> = []
) => {
  const decodedParams: {
    [x in (typeof allowedKeys)[number]]: string | number;
  } = converObjArraytoString(
    getParamsObjFromEncodedString(window.location.search)
  );

  if (allowedKeys.length) {
    const removableKeys = Object.keys(decodedParams).filter(
      key => !allowedKeys.includes(key)
    );
    removableKeys.forEach(key => delete decodedParams[key]);
  }

  if (decodedParams[`${apiId}_id__in`]) {
    decodedParams.id__in = decodedParams[`${apiId}_id__in`];
    delete decodedParams[`${apiId}_id__in`];
  }

  return decodedParams;
};
