export const GLOBAL_CONSTS = {
  BULK_CREATE_SYNC_LIMIT: 20000,
  DB_NAME: 'SixSense',
  STORE_NAME: 'refresh'
};

export const TOOL_KEYS = {
  r: 'create-box',
  s: 'select',
  v: 'pan',
  z: 'zoom'
};

export const DEFECT_HOT_KEYS = {};

export const clearDefectHotKeys = () => {
  Object.keys(DEFECT_HOT_KEYS).forEach(key => delete DEFECT_HOT_KEYS[key]);
};

export const setDefectHotKeys = defects => {
  clearDefectHotKeys();
  defects
    .filter(item => item.hot_key)
    .forEach(item => (DEFECT_HOT_KEYS[item.hot_key] = item));
};

export const FILTER_KEYS = {
  FOLDER_FILTER_KEY: 'upload_session_id__in',
  FOLDER_TAGS_KEY: 'upload_session__tags__in',
  USECASE_FILTER_KEY: 'use_case_id__in',
  IMAGE_TAG_FILTER_KEY: 'tags__in',
  WAFER_FILTER_KEY: 'wafer_id__in',
  ML_MODEL_FILTER_KEY: 'ml_model_id__in',
  GROUND_TRUTH_FILTER_KEY: 'gt_label__in',
  GROUND_TRUTH_CLASSIFICATION_FILTER_KEY: 'gt_classification_label__in',
  GROUND_TRUTH_DETECTION_FILTER_KEY: 'gt_detection_label__in',
  TRAINING_TYPE_FILTER_KEY: 'train_type__in',
  BOOKMARK_FILTER_KEY: 'is_bookmarked',
  UPLOAD_SESSION_TAGS_FILTER_KEY: 'upload_session__tags__in',
  WAFER_TAGS_KEY: 'wafer_map__tags__in',
  EXCLUDED_IMAGE_TAG_FILTER_KEY: 'tags_not__in',
  DATE_FILTERS_KEYS: [
    'date',
    'date__gte',
    'date__lte',
    'created_ts__after',
    'created_ts__before'
  ],
  META_INFO_FILTER_KEY: 'meta_info__'
};

export const REVIEW_CONTAINER_META_MAX_HEIGHT =
  'REVIEW_CONTAINER_META_MAX_HEIGHT';
