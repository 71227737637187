export const SET_USE_CASE_DICT = 'SET_USE_CASE_DICT';
export const SET_WAFER_DICT = 'SET_WAFER_DICT';
export const SET_MODEL_DICT = 'SET_MODEL_DICT';
export const SET_USE_CASE_DICT_LOADING = 'SET_USE_CASE_DICT_LOADING';
export const SET_WAFER_DICT_LOADING = 'SET_WAFER_DICT_LOADING';
export const SET_MODEL_DICT_LOADING = 'SET_MODEL_DICT_LOADING';
export const SAVE_WAFER_TABLE_STRUCTURE = 'SAVE_WAFER_TABLE_STRUCTURE';
export const SET_IMAGE_FIT = 'SET_IMAGE_FIT';
export const SAVE_LOT_TABLE_STRUCTURE = 'SAVE_LOT_TABLE_STRUCTURE';
export const SAVE_FILE_SET_TABLE_STRUCTURE = 'SAVE_FILE_SET_TABLE_STRUCTURE';
