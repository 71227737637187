import api from 'app/api';
import axios from 'app/api/base';
import { globalQueryClient } from 'app/configs/queryClientConfig';
import store from 'store/index';
import { keyBy } from 'lodash';
import queryString from 'query-string';
import {
  addUserClassification,
  changeGridMode,
  getFileSetApiParamAnnotationType,
  getFileSetLocationParams,
  getUserClassification,
  handleUserClassificationChange,
  removeFileSet,
  resetFileSetLocalDefects,
  setActiveImg,
  setContainerMeta,
  setFetchingReviewData,
  setFetchingReviewDataBackground,
  setFileSetLocalDefects,
  setFileSetLocalDefectsSavingStatus,
  setOtherDefects,
  setReviewData,
  setReviewDataNextApi,
  setSelectAll,
  setSelectedTool,
  updateFilsetById,
  updateImageModes,
  updateTransferableImageIndexes,
  updateUserClassification
} from 'store/reviewData/actions';
import {
  encodeURL,
  formatFileSetData,
  getAIDefects,
  getDateFromParams,
  updateNextDataURL
} from './helpers';
import {
  DEFECT_HOT_KEYS,
  REVIEW_CONTAINER_META_MAX_HEIGHT,
  TOOL_KEYS
} from './constants';
import {
  APPLIED_FOR_ALL_MODEL_ID_FROM_SESSION_STORAGE,
  CLUSTERING,
  MANUAL_CLASSIFY,
  SORTING_CONSTANTS
} from 'store/reviewData/constants';
import { getFileSetIds } from './fileSet';
import { infoToast, warningToast } from './toast';

function calcContainerLayout({
  originalImgHeight,
  originalImgWidth,
  totalItemCount,
  totalWidth,
  maxHeight = 200
}) {
  const imgAspectRatio = originalImgWidth / originalImgHeight;
  let rowAspectRatio = totalWidth / maxHeight;

  const gapBetweenImages = 16;
  const minWidth = 200;

  let maxPossibleImagesWidth;
  let imgWidth = 0;

  let imgCount = 0;

  if (Math.ceil(imgAspectRatio) >= Math.floor(rowAspectRatio)) {
    imgCount = 1;
  } else {
    if (imgAspectRatio < 0.5) {
      maxHeight = 800;
      rowAspectRatio = totalWidth / maxHeight;
    }

    while (imgWidth < minWidth) {
      if (imgWidth) {
        imgCount = 0;
        maxHeight += 20;
        rowAspectRatio = totalWidth / maxHeight;
      }

      let combinedAspectRatioOfImages = 0;

      while (combinedAspectRatioOfImages <= rowAspectRatio) {
        combinedAspectRatioOfImages += imgAspectRatio;
        imgCount += 1;
      }

      maxPossibleImagesWidth = totalWidth - gapBetweenImages * imgCount;
      imgWidth = maxPossibleImagesWidth / imgCount;
    }
  }

  if (!maxPossibleImagesWidth) {
    maxPossibleImagesWidth = totalWidth - gapBetweenImages * imgCount;
  }

  if (!imgWidth) {
    imgWidth = maxPossibleImagesWidth / imgCount;
  }

  const imgHeight = imgWidth / imgAspectRatio;

  const colCount = imgCount > totalItemCount ? totalItemCount : imgCount;
  const rowCount = Math.ceil(totalItemCount / colCount);
  const cardHeight = imgHeight + 35 + 16;

  return {
    rowCount,
    colCount,
    cardHeight,
    totalHeight: cardHeight * rowCount,
    cardWidth: imgWidth + 16
  };
}

function handleCardSelect(index, isSelected) {
  const { getState, dispatch } = store;
  const { activeImg } = getState().review;

  if (isSelected) {
    if (activeImg.length > 1)
      dispatch(setActiveImg(activeImg.filter(x => x !== index)));
  } else {
    dispatch(setActiveImg([...activeImg, index]));
  }
}

function handleCardClick(event, index, isSelected, forceUpdate = false) {
  const { getState, dispatch } = store;
  const { activeImg, selectAll, data: fileSets } = getState().review;

  if (event.ctrlKey || event.metaKey) {
    handleCardSelect(index, isSelected);
  } else if (event.shiftKey) {
    const tempList = [];

    if (activeImg.sort()[activeImg.length - 1] < index) {
      for (
        let i = activeImg.sort()[activeImg.length - 1] + 1;
        i <= index;
        i++
      ) {
        tempList.push(i);
      }
    } else {
      for (let i = index; !activeImg.includes(i); i++) {
        tempList.push(i);
      }
    }

    dispatch(setActiveImg([...new Set([...activeImg, ...tempList])]));
  } else {
    if (
      activeImg.length === 1 &&
      activeImg[0] === index &&
      !forceUpdate &&
      !selectAll
    ) {
      return;
    }

    const tempIndex = activeImg[0];

    dispatch(setActiveImg([index]));

    if (selectAll) dispatch(setSelectAll(false));

    if (fileSets[index].use_case !== fileSets[tempIndex].use_case) {
      dispatch(setOtherDefects([]));
      globalQueryClient.invalidateQueries('useCaseDefects');
    }

    // const { state } = location;
  }
}

function handleCardDoubleClick(index) {
  const { getState, dispatch } = store;
  const { activeGridMode } = getState().review;

  if (activeGridMode === 'Grid View') {
    dispatch(setActiveImg([index]));
    dispatch(changeGridMode('Canvas View'));
  }
}

function handleCardMultiSelect(index, direction) {
  const { getState, dispatch } = store;
  const { activeImg } = getState().review;

  if (activeImg.includes(index)) {
    if (activeImg.length > 1) {
      let updatedIndex = index;

      if (direction === 'down') {
        if (activeImg.includes(index - 1)) {
          updatedIndex = index - 1;
        }
      } else if (direction === 'up' && activeImg.includes(index + 1)) {
        updatedIndex = index + 1;
      }

      dispatch(setActiveImg(activeImg.filter(d => d !== updatedIndex)));
    }
  } else {
    dispatch(setActiveImg([...activeImg, index]));
  }
}

function handleImgClickNavigation(direction, event, forceUpdate = false) {
  const { getState, dispatch } = store;
  const { activeImg, selectAll, data: fileSets } = getState().review;

  let index;

  const currentActiveImage = event.shiftKey
    ? activeImg[activeImg.length - 1]
    : activeImg[0];

  if (direction === 'down') {
    index = currentActiveImage + 1;

    if (index > fileSets.length - 1) return;
  } else if (direction === 'up') {
    index = currentActiveImage - 1;

    if (index < 0) return;
  }

  if (event.shiftKey) {
    return handleCardMultiSelect(index, direction);
  }

  if (
    activeImg.length === 1 &&
    activeImg[0] === index &&
    !forceUpdate &&
    !selectAll
  ) {
    return;
  }

  const tempIndex = activeImg[0];

  dispatch(setActiveImg([index]));
  dispatch(setSelectAll(false));

  if (fileSets[index].use_case !== fileSets[tempIndex].use_case) {
    dispatch(setOtherDefects([]));
    globalQueryClient.invalidateQueries('useCaseDefects');
  }
}

function handleToggleSelectAll() {
  const { getState, dispatch } = store;
  const { selectAll } = getState().review;

  dispatch(setSelectAll(!selectAll));
}

function handleToolSelect(tool) {
  const { dispatch, getState } = store;
  const { selectedTool, activeImg, data } = getState().review;
  const { usecases } = getState().helpers;

  const useCaseId = data[activeImg]?.use_case;
  const useCaseType = usecases[useCaseId]?.type;

  if (useCaseType === 'CLASSIFICATION' && tool === 'create-box') return;

  if (tool === 'zoom' && selectedTool === 'zoom') {
    tool = 'zoom-out';
  }

  if (selectedTool === tool) return;

  dispatch(setSelectedTool(tool));
}

function handleImgKeyNavigation(e, annotationType, onLabelsChange) {
  // prevent hotkey execution when review state object ahs hot keys disabled like for popups
  const disableHotKeys = store.getState().review.disableHotKeys;
  if (disableHotKeys) return;

  // Prevent keydown event from being handled multiple times while held down the key or combo
  if (e.repeat) {
    e.preventDefault();
    e.stopPropagation();
    return;
  }

  const avoidableKeys = ['Shift', 'Alt', 'Meta', 'Control'];

  // newShortCutKey will always follow the this sequence Ctrl, Shift, Alt, Arrow, Alphabets
  let newShortCutKey = [];

  if (e.ctrlKey || e.metaKey) newShortCutKey.push('ctrl');
  if (e.shiftKey) newShortCutKey.push('shift');
  if (e.altKey) newShortCutKey.push('alt');
  if (e.key.includes('Arrow')) newShortCutKey.push(e.key.split('Arrow')[1]);
  if (e.key && !avoidableKeys.includes(e.key))
    newShortCutKey.push(e.key.toLowerCase());

  newShortCutKey = newShortCutKey.join('+');

  if (DEFECT_HOT_KEYS[newShortCutKey]) {
    e.preventDefault();
    e.stopPropagation();
    const { getState } = store;
    const { activeImg, data } = getState().review;
    onLabelsChange?.({
      ...DEFECT_HOT_KEYS[newShortCutKey],
      ids: [
        ...(activeImg.map(
          i =>
            data[i][annotationType === CLUSTERING ? 'fileSetId' : 'wafer_dot']
        ) || [])
      ]
    });

    return handleUserClassificationChange(
      DEFECT_HOT_KEYS[newShortCutKey],
      annotationType
    );
  }

  if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
    e.preventDefault();
    e.stopPropagation();

    return handleToggleSelectAll();
  }

  if (!(e.ctrlKey || e.metaKey) && TOOL_KEYS[e.key.toLowerCase()]) {
    e.preventDefault();
    e.stopPropagation();

    return handleToolSelect(TOOL_KEYS[e.key.toLowerCase()]);
  }

  if (
    document.activeElement.tagName.toLowerCase() !== 'input' &&
    e.key.includes('Arrow') &&
    !(e.ctrlKey || e.shiftKey || e.metaKey)
  ) {
    e.preventDefault();
    e.stopPropagation();

    const key = e.key.split('Arrow')[1];

    switch (key) {
      case 'Down':
      case 'Right':
        handleImgClickNavigation('down', e);
        break;

      case 'Up':
      case 'Left':
        handleImgClickNavigation('up', e);
        break;

      default:
        return;
    }
  }
}

function calcCardIndex(rowIdx, colIdx, colCount) {
  return colCount * rowIdx + colIdx;
}

export function addImageModeParam(
  annotationType,
  activeImageMode,
  parsedParams
) {
  if (annotationType && activeImageMode) {
    const imageModeParam = getFileSetApiParamAnnotationType(
      annotationType,
      activeImageMode,
      false
    )
      ?.slice(1)
      .split('=');

    if (imageModeParam.length > 0) {
      parsedParams[imageModeParam[0]] = imageModeParam[1];
    }
  }
}

function handleAddFolderTags(tags, annotationType) {
  const { getState, dispatch } = store;
  const {
    activeImg,
    selectAll,
    data: fileSets,
    searchText,
    appliedForAllModelId,
    selectedWaferDots,
    activeImageMode
  } = getState().review;

  const parsedParams = getDateFromParams(
    getFileSetLocationParams(window.location.search),
    undefined,
    true
  );

  let encodedString = {};

  if (!selectAll) {
    encodedString = btoa(
      `id__in=${getFileSetIds(selectAll, activeImg, fileSets)}`
    );
  } else {
    delete parsedParams['allWafersId'];

    if (appliedForAllModelId) {
      parsedParams['ml_model_id__in'] = appliedForAllModelId;
    }

    if (searchText) {
      parsedParams['files__name__icontains'] = searchText;
    }

    if (selectedWaferDots?.length > 0) {
      parsedParams['wafer_dot__in'] = selectedWaferDots.join(',');
    }
    addImageModeParam(annotationType, activeImageMode, parsedParams);

    encodedString = encodeURL(parsedParams);
  }

  api
    .updateTagsOnFilesets({
      tag_ids: tags.map(x => x.id),
      file_set_filters: encodedString
    })
    .then(() => {
      infoToast('Tags Added to images successfully');
      if (selectAll) {
        reloadReviewPage();
        return;
      }
      if (activeImg.length < 50) {
        api
          .getFilesetTags(getFileSetIds(selectAll, activeImg, fileSets))
          .then(res => {
            const data = formatFileSetData(res?.results || []);
            dispatch(updateFilsetById(keyBy(data, 'id')));
            dispatch(setSelectAll(false));
          })
          .catch(() => {
            infoToast('Tags are not updated in UI please refresh the page.');
          });
      } else {
        infoToast('Please refresh to see updated tags.');
      }
    })
    .catch(() => {
      infoToast('Something went wrong.');
    });
}

function handleRemoveFolderTags(tags, isAllRemove = false, annotationType) {
  const { getState, dispatch } = store;
  const {
    activeImg,
    selectAll,
    data: fileSets,
    searchText,
    appliedForAllModelId,
    selectedWaferDots,
    activeImageMode
  } = getState().review;

  const parsedParams = getDateFromParams(
    getFileSetLocationParams(window.location.search),
    undefined,
    true
  );

  let encodedString = null;

  if (!selectAll) {
    encodedString = btoa(
      `id__in=${getFileSetIds(selectAll, activeImg, fileSets)}`
    );
  } else {
    delete parsedParams['allWafersId'];

    if (appliedForAllModelId) {
      parsedParams['ml_model_id__in'] = appliedForAllModelId;
    }

    if (searchText) {
      parsedParams['files__name__icontains'] = searchText;
    }

    if (selectedWaferDots?.length > 0) {
      parsedParams['wafer_dot__in'] = selectedWaferDots.join(',');
    }
    addImageModeParam(annotationType, activeImageMode, parsedParams);

    encodedString = encodeURL(parsedParams);
  }

  api
    .removeTagsOnFilesets(
      isAllRemove
        ? { remove_all_tags: true, file_set_filters: encodedString }
        : { tag_ids: tags.map(x => x.id), file_set_filters: encodedString }
    )
    .then(() => {
      infoToast('Tag removed from images successfully');
      if (selectAll) {
        reloadReviewPage();
        return;
      }
      if (activeImg.length < 50) {
        api
          .getFilesetTags(getFileSetIds(selectAll, activeImg, fileSets))
          .then(res => {
            const data = formatFileSetData(res?.results || []);
            dispatch(updateFilsetById(keyBy(data, 'id')));
            dispatch(setSelectAll(false));
          })
          .catch(() => {
            infoToast('Tags are not updated in UI please refresh the page.');
          });
      } else {
        infoToast('Please refresh to see updated tags.');
      }
    })
    .catch(() => {
      infoToast('Something went wrong.');
    });
}

function loadImageList(ref, lastCursorCallRef, annotationType) {
  const { getState, dispatch } = store;
  const {
    fetchingReviewData,
    next: nextData,
    appliedForAllModelId,
    activeImageMode,
    useAIAssistance,
    data
  } = getState().review;

  const isInViewPort = offset => {
    const lastLoadedChild = document.getElementById(
      `thumbnail-card-${data.length - (offset ?? 1)}`
    );

    if (!ref.current || !lastLoadedChild) {
      return false;
    }

    const parentRect = ref.current.getBoundingClientRect();
    const childRect = lastLoadedChild.getBoundingClientRect();

    return childRect.top <= parentRect.bottom;
  };

  if (
    (isInViewPort() || isInViewPort(20)) &&
    !fetchingReviewData &&
    !!nextData &&
    lastCursorCallRef.current !== nextData
  ) {
    dispatch(setFetchingReviewDataBackground(true));

    return axios
      .get(`${nextData}`)
      .then(({ data: res }) => {
        lastCursorCallRef.current = nextData;

        dispatch(setReviewDataNextApi(updateNextDataURL(res.next)));

        dispatch(
          setReviewData({
            data: res?.results || [],
            isNewData: false,
            annotationType,
            activeImageMode,
            modelId:
              appliedForAllModelId && useAIAssistance
                ? appliedForAllModelId
                : undefined
          })
        );
      })
      .catch(e => {
        dispatch(setFetchingReviewDataBackground(false));
      });
  }

  return new Promise(resolve => {
    resolve();
  });
}

function getCurrentModelFromState() {
  const {
    appliedForAllModelId,
    fileSetDefects,
    data,
    activeImg,
    useAIAssistance
  } = store.getState().review;
  const { model_classifications = [], model_detections = [] } =
    fileSetDefects[data[activeImg[0]]?.id] || {};

  let modelId = null;

  if (!useAIAssistance) return null;

  if (appliedForAllModelId) {
    modelId = appliedForAllModelId;
  } else if (model_classifications.length) {
    modelId = model_classifications[0]?.ml_model;
  } else if (model_detections.length) {
    modelId = model_classifications[0]?.ml_model.id;
  }

  return modelId;
}

function getSortingParams() {
  const searchParams = new URLSearchParams(window.location.search);
  let sorting = searchParams.get('sorting');

  if (sorting) {
    sorting = queryString.parse(sorting);
  } else {
    sorting = {
      sortDirection: 'ascending',
      sortBy: 'created_ts'
    };
  }

  if (!sorting?.sortBy) return '';

  let params = `&ordering=${sorting.sortDirection === 'ascending' ? '' : '-'}${
    sorting?.sortBy
  }`;

  if (
    sorting.sortBy === SORTING_CONSTANTS.SIMILARITY ||
    sorting.sortBy === SORTING_CONSTANTS.AI_OUTPUT
  ) {
    const modelId = getCurrentModelFromState();
    params = params + `&ordering_ml_model=${modelId}`;
  }

  return params;
}

function isModelExist(id) {
  const { modelsDict } = store.getState().review;

  if (modelsDict[id]) return true;
  return false;
}

function createRegionPayload(region) {
  const { type, x, y, w, h, tags } = region;
  const tempObj = {};

  tempObj.region = {
    type,
    coordinates: {
      x,
      y,
      w,
      h
    }
  };

  tempObj.defects = [...new Set(tags?.map(tag => tag.id) ?? [])];

  return tempObj;
}

function moveFocusToParent() {
  document.elementFromPoint(0, 0).closest('div').focus();
  return;
}

const handleOtherDefectChange = async (defect, annotationType) => {
  const { getState, dispatch } = store;
  const { data, activeImg, fileSetDefects, activeImageMode } =
    getState().review;

  const fileSet = data[activeImg[0]];
  const defectIds = [];

  if (!fileSet) return;

  const showWarningOnLabelChange =
    (
      getAIDefects(
        fileSetDefects[data[activeImg[0]].id],
        getState().helpers.usecases[fileSet.use_case].type
      ) || []
    ).some(d => !!d.is_killer) &&
    defect.id &&
    !defect.is_killer &&
    process.env.REACT_APP_SHOW_WARNING_FOR_AI_KILLER_GT_NON_KILLER_SELECTION ===
      'true';

  if (Array.isArray(defect)) {
    defect.map(item => defectIds.push(item.id));
  } else if (defect.id) {
    defectIds.push(defect.id);
  }

  if (annotationType === MANUAL_CLASSIFY) {
    if (isFileSetUpdatePermission(fileSet.id)) {
      if (defectIds.length > 0) {
        dispatch(
          setFileSetLocalDefects({
            id: fileSet.id,
            defect: Array.isArray(defect) ? defect[0] : defect
          })
        );

        if (
          activeImageMode.toLowerCase() === 'unclassified' ||
          activeImageMode.toLowerCase() === 'unaudited'
        ) {
          addActiveImgToTransferableImageIndexes();
        } else if (
          (activeImageMode.toLowerCase() === 'classified' &&
            !isAutoModel(fileSet.id)) ||
          activeImageMode.toLowerCase() === 'audited'
        ) {
          removeAcitveImgFromTransferableImageIndexes();
        }
      } else {
        dispatch(setFileSetLocalDefects({ id: fileSet.id, defect: {} }));

        if (
          activeImageMode.toLowerCase() === 'unclassified' ||
          activeImageMode.toLowerCase() === 'unaudited'
        ) {
          removeAcitveImgFromTransferableImageIndexes();
        } else if (
          (activeImageMode.toLowerCase() === 'classified' &&
            !isAutoModel(fileSet.id)) ||
          activeImageMode.toLowerCase().includes('audited')
        ) {
          addActiveImgToTransferableImageIndexes();
        }
      }
    }
    showWarningOnLabelChange && warningToast('Label might be incorrect.');
    return;
  }

  const { userInfo } = getState().common;

  const modelId = getCurrentModelFromState();

  const shouldUpdate = fileSetDefects[fileSet.id]['gt_classifications'];

  if (shouldUpdate) {
    const gtDefectId = fileSetDefects[fileSet.id]['gt_classifications']['id'];

    await dispatch(
      updateUserClassification({
        gtDefectId,
        defectIds,
        fileSet,
        userInfo: userInfo.id,
        modelId,
        annotationType
      })
    );
  } else {
    await dispatch(
      addUserClassification({
        fileSet,
        defects: defectIds,
        user: userInfo.id,
        modelId: modelId,
        annotationType
      })
    );
  }

  dispatch(getUserClassification(fileSet.id));
  !showWarningOnLabelChange
    ? infoToast(`1 image is updated successfully.`)
    : warningToast('Label might be incorrect.');
};

const getDictinctModelFromFileDefect = fileSetDefects => {
  return [
    ...new Set(
      Object.values(fileSetDefects)
        .map(item => {
          const { model_classifications, model_detections } = item;
          if (model_classifications) {
            return model_classifications.map(model => model.ml_model);
          } else if (model_detections) {
            return model_detections.map(model => model.ml_model);
          }
          return [];
        })
        .flat()
    )
  ];
};

function setContainerLayout(ref, itemCount) {
  return new Promise((resolve, reject) => {
    const { getState, dispatch } = store;
    const { data } = getState().review;

    if (!itemCount) {
      dispatch(setContainerMeta(null));
    } else if (itemCount && data.length) {
      const { offsetWidth, offsetTop } = ref.current;
      const maxHeight = localStorage.getItem(REVIEW_CONTAINER_META_MAX_HEIGHT);
      let idx = 0;

      const img = new Image();

      img.onload = () => {
        const meta = calcContainerLayout({
          originalImgHeight: img.height,
          originalImgWidth: img.width,
          totalItemCount: itemCount,
          totalWidth: offsetWidth,
          maxHeight: Number(maxHeight) || undefined
        });

        dispatch(
          setContainerMeta({
            ...meta,
            containerHeight: window.innerHeight - offsetTop - 30,
            totalWidth: offsetWidth,
            originalImgHeight: img.height,
            originalImgWidth: img.width,
            totalItemCount: itemCount,
            totalWidth: offsetWidth
          })
        );

        resolve();
      };

      img.onerror = () => {
        if (idx <= Math.min(data.length - 1, 4)) {
          idx += 1;
          img.src = data[idx].src;
        } else {
          reject(new Error('Failed to load image. Please try again.'));
        }
      };

      img.src = data[idx].src;
    }
  });
}

const setApplyForAllModelToSession = () => {
  const { getState } = store;
  const { appliedForAllModelId } = getState().review;

  if (appliedForAllModelId) {
    sessionStorage.setItem(
      APPLIED_FOR_ALL_MODEL_ID_FROM_SESSION_STORAGE,
      appliedForAllModelId
    );
  }
};

const reloadReviewPage = () => {
  setApplyForAllModelToSession();
  window.location.reload();
};

const isSameFilteredLocation = url => {
  const { pathname: windowPath, search: windowSearchQuery } = window.location;
  const [urlPathName, urlSearchQuery] = url.split('?');

  if (windowPath === urlPathName) {
    if (windowSearchQuery.substring(1) === urlSearchQuery) {
      return true;
    }
    return false;
  }

  return false;
};

function resetFileSetLocalDefectsState() {
  store.dispatch(resetFileSetLocalDefects());
}

function resetTransferableImageIndexes() {
  const { getState, dispatch } = store;
  const { transferableImageIndexes } = getState().review;

  transferableImageIndexes.clear();
  dispatch(updateTransferableImageIndexes(transferableImageIndexes));
  resetFileSetLocalDefectsState();
}

const reArrangeManualAuditFiles = annotationType => {
  const { getState, dispatch } = store;

  const {
    transferableImageIndexes,
    data: fileSets,
    activeImg
  } = getState().review;

  if (transferableImageIndexes.size === 0) return;

  const fileIds = [];

  for (const x of transferableImageIndexes) {
    fileIds.push(fileSets[x].id);
  }

  let highest = Number.NEGATIVE_INFINITY;
  const activeImgMap = {};
  const len = activeImg.length;

  for (let i = 0; i < len; i++) {
    const x = activeImg[i];
    if (x > highest) highest = x;
    activeImgMap[x] = i;
  }

  let numberOfTransferedFiles = 0;

  for (let i = 0; i <= highest; i++) {
    const x = activeImgMap[i];

    if (transferableImageIndexes.has(i)) {
      numberOfTransferedFiles++;
    }

    if (i && x !== undefined) {
      activeImg[x] = activeImg[x] - numberOfTransferedFiles;
    }
  }

  if (!activeImg.length) activeImg[0] = 0;

  dispatch(removeFileSet(fileIds));
  dispatch(updateImageModes(fileIds));
  resetTransferableImageIndexes();

  dispatch(setActiveImg([...activeImg]));

  infoToast(
    `${
      fileIds.length > 1
        ? `${fileIds.length} images are`
        : `${fileIds.length} image is`
    } ${
      annotationType === MANUAL_CLASSIFY ? 'classified' : 'Audited'
    } successfully.`
  );
};

export function removeAcitveImgFromTransferableImageIndexes() {
  const { getState, dispatch } = store;
  const { transferableImageIndexes, activeImg } = getState().review;

  for (const x of activeImg) {
    transferableImageIndexes.delete(x);
  }

  dispatch(
    updateTransferableImageIndexes(new Set([...transferableImageIndexes]))
  );
}

export function addActiveImgToTransferableImageIndexes() {
  const { getState, dispatch } = store;
  const { transferableImageIndexes, activeImg } = getState().review;

  dispatch(
    updateTransferableImageIndexes(
      new Set([...transferableImageIndexes, ...activeImg])
    )
  );
}

export function isAutoModel(fileSetId) {
  const { getState } = store;
  const { fileSetDefects, modelsDict } = getState().review;

  return fileSetDefects[fileSetId]['model_classifications']?.some(d => {
    return d.classification_defects.some(
      e =>
        Number(e.confidence) >=
        Number(modelsDict[d.ml_model].confidence_threshold)
    );
  });
}

function isAllowUnload() {
  const { getState } = store;
  const { fileSetLocalDefects } = getState().review;

  return Object.values(fileSetLocalDefects).length > 0;
}

export function handleBeforeUnload(event) {
  if (isAllowUnload()) {
    event.preventDefault();
    event.returnValue = 'Unsaved data. Are you sure?';
    return 'Unsaved data. Are you sure?';
  }
}

export async function handleBeforeBack() {
  const { getState } = store;
  const { fileSetLocalDefects } = getState().review;

  const entries = Object.entries(fileSetLocalDefects);

  if (entries.length > 0) {
    window.history.forward();
    await bulkUpdateFilesetDefects();
    window.history.back();
  }
}

export async function bulkUpdateFilesetDefects() {
  const { getState, dispatch } = store;
  const { review, common } = getState();
  const { fileSetLocalDefects } = review;
  const { userInfo } = common;
  const entries = Object.entries(fileSetLocalDefects);

  try {
    if (
      entries.length > 0 &&
      review.fileSetLocalDefectsSavingStatus !== 'LOADING'
    ) {
      dispatch(setFileSetLocalDefectsSavingStatus('LOADING'));

      const payload = {
        file_defects: entries.reduce((acc, curr) => {
          acc[curr[0]] = Object.values(curr[1]).length > 0 ? [curr[1].id] : [];
          return acc;
        }, {}),
        userId: userInfo.id
      };

      await api.addBulkClassification(payload);

      dispatch(setFileSetLocalDefectsSavingStatus('NONE'));
    }
  } catch (err) {
    console.error(err);
    dispatch(setFileSetLocalDefectsSavingStatus('ERROR'));
    throw err;
  }
}

export function isFilesetLabelUpdatedByAdmin(fileSetId) {
  const { getState } = store;
  const { fileSetDefects } = getState().review;

  return !!fileSetDefects[fileSetId]?.['gt_classifications']
    ?.is_created_by_admin;
}

export function isFileSetUpdatePermission(fileSetId) {
  const { getState } = store;
  const { userInfo } = getState().common;

  return !!userInfo?.is_staff || !isFilesetLabelUpdatedByAdmin(fileSetId);
}

export {
  calcContainerLayout,
  handleCardClick,
  handleCardDoubleClick,
  calcCardIndex,
  handleImgClickNavigation,
  handleImgKeyNavigation,
  handleAddFolderTags,
  handleRemoveFolderTags,
  loadImageList,
  getSortingParams,
  handleCardSelect,
  isModelExist,
  getCurrentModelFromState,
  createRegionPayload,
  moveFocusToParent,
  handleOtherDefectChange,
  getDictinctModelFromFileDefect,
  setContainerLayout,
  setApplyForAllModelToSession,
  reloadReviewPage,
  isSameFilteredLocation,
  resetTransferableImageIndexes,
  reArrangeManualAuditFiles
};
