import 'react-toastify/dist/ReactToastify.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core/styles';
import sixsenseTheme from 'app/configs/theme';
import Routing from 'app/services/Routing';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import { Suspense, useEffect, useRef, useState } from 'react';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import store from './store';
import { globalQueryClient } from 'app/configs/queryClientConfig';
import { StableNavigateContextProvider } from 'context/StableNavigateContext';
import { handleBeforeBack } from 'app/utils/reviewData';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();
const autoRefreshThresholdHours = 3;

function App() {
  const inactiveTime = useRef(null);

  const handleVisibilityChange = e => {
    if (e.target.visibilityState === 'hidden') {
      inactiveTime.current = e.timeStamp;
    } else if (e.target.visibilityState === 'visible') {
      if (inactiveTime.current) {
        const timeDiff = e.timeStamp - inactiveTime.current;
        if (timeDiff > autoRefreshThresholdHours * 60 * 60 * 1000) {
          window.location.reload();
        }
        inactiveTime.current = null;
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      window?.plugSDK?.init?.({
        app_id:
          'DvRvStPZG9uOmNvcmU6ZHZydi1pbi0xOmRldm8vMjE4ZlpHdnN4bDpwbHVnX3NldHRpbmcvMV9ffHxfXzIwMjUtMDItMDcgMDg6NDc6MjMuMDQ1MDkwMTA2ICswMDAwIFVUQw==xlxendsDvRv',
        disable_plug_chat_window: true
      });
    }, 5000);
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', handleBeforeBack);
    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('popstate', handleBeforeBack);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <Suspense
      fallback={
        <CircularProgress
          style={{ position: 'absolute', top: '45%', left: '50%' }}
        />
      }
    >
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <ThemeProvider theme={sixsenseTheme}>
            <CssBaseline />

            <QueryClientProvider client={globalQueryClient}>
              <BrowserRouter>
                <StableNavigateContextProvider>
                  <Routing />
                </StableNavigateContextProvider>
              </BrowserRouter>

              <ToastContainer
                position='bottom-right'
                autoClose={2500}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName='toastContainer'
                bodyClassName='toastBody'
                limit={1}
              />
            </QueryClientProvider>
          </ThemeProvider>
        </Provider>
      </StylesProvider>
    </Suspense>
  );
}

export default App;
