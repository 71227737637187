import { getLimitOffset } from 'app/utils/table';
import { apiCallBack } from '../ApiMethods';

import { handleFileDownload } from 'app/utils';
import { getDateFromParams } from 'app/utils/helpers';

import type {
  GetWaferbookByLotId,
  GetWaferbookData,
  WaferTag,
  WaferTagById,
  WaferbookByLotId,
  WaferbookData
} from './types';

const API_ENDPOINT = `/api/v1/classif-ai/wafer-map/wafer-book/`;

export const getWaferBookData = (params: ApiBase<GetWaferbookData>) => {
  return apiCallBack({
    method: 'GET',
    url: API_ENDPOINT,
    ...params,
    transformParams: (baseParams, locationParams) => {
      const { limit, ...restBaseParams } = baseParams ?? {};
      const {
        page = 1,
        rowsPerPage,
        ...rest
      } = (locationParams ?? {}) as object & {
        page: number;
        rowsPerPage: number | undefined;
        is_adc_enabled: string | undefined;
        wafer_map__tags__in: string | string[] | undefined;
        tags__in: string | string[] | undefined;
      };

      if (rest.is_adc_enabled === 'false') {
        delete rest.is_adc_enabled;
      }

      if (rest.wafer_map__tags__in) {
        rest.tags__in = rest.wafer_map__tags__in;
        delete rest.wafer_map__tags__in;
      }

      return {
        ...getDateFromParams(rest, undefined, true),
        ...restBaseParams,
        ...(limit ? getLimitOffset(page, limit) : {})
      };
    }
  }).then(_ => _.data as PaginatedTableResponseType<WaferbookData>);
};

export const getAllWaferTags = () => {
  return apiCallBack({
    method: 'GET',
    url: `/api/v1/classif-ai/wafer-map-tags/`
  }).then(_ => {
    const response = _.data as PaginatedTableResponseType<WaferTag>;

    response.results = response.results.map(d => ({ ...d, tag_name: d.name }));

    return response;
  });
};

export const getAllWaferTagsById = (params: ApiBase<{ ids: number[] }>) => {
  return apiCallBack({
    method: 'GET',
    url: `/api/v1/classif-ai/wafer-map/tags/`,
    ...params,
    transformParams: baseParams => {
      const { ids = [] } = baseParams ?? {};
      return { wafer_filters: btoa(`id__in=${ids.join(',')}`) };
    }
  }).then(
    _ =>
      (_.data as { data: any }).data as Record<
        string | number | symbol,
        WaferTagById[]
      >
  );
};

export const getWaferBookByLotId = (params: ApiBase<GetWaferbookByLotId>) => {
  return apiCallBack({
    method: 'GET',
    url: `/api/v1/classif-ai/lot/lot-book/`,
    ...params,
    transformParams: (baseParams, locationParams) => {
      const { limit, ...restBaseParams } = baseParams ?? {};
      const {
        page = 1,
        rowsPerPage,
        ...rest
      } = (locationParams ?? {}) as object & {
        page: number;
        rowsPerPage: number | undefined;
        lot_id__in: number | undefined;
        is_adc_enabled: string | undefined;
        id__in: number | undefined;
        status__in: string | string[] | undefined;
        wafer_maps__status__in: string | string[] | undefined;
        wafer_id__in: string | string[] | undefined;
        wafer_maps__id__in: string | string[] | undefined;
        wafer_map__tags__in: string | string[] | undefined;
        wafer_maps__tags__in: string | string[] | undefined;
      };

      if (rest.is_adc_enabled === 'false') {
        delete rest.is_adc_enabled;
      }

      if (rest.lot_id__in) {
        rest.id__in = rest.lot_id__in;
        delete rest.lot_id__in;
      }

      if (rest.status__in) {
        rest.wafer_maps__status__in = rest.status__in;
        delete rest.status__in;
      }

      if (rest.wafer_id__in) {
        rest.wafer_maps__id__in = rest.wafer_id__in;
        delete rest.wafer_id__in;
      }
      if (rest.wafer_map__tags__in) {
        rest.wafer_maps__tags__in = rest.wafer_map__tags__in;
        delete rest.wafer_map__tags__in;
      }

      return {
        ...restBaseParams,
        ...getDateFromParams(rest, undefined, true),
        ...getLimitOffset(page, limit)
      };
    }
  })
    .then(_ => _.data as PaginatedTableResponseType<WaferbookByLotId>)
    .catch(err => console.log(err));
};

export const getLotIds = (params: ApiBase<GetWaferbookByLotId>) => {
  return apiCallBack({
    method: 'GET',
    url: `/api/v1/classif-ai/lot/`,
    ...params,
    transformParams(baseParams) {
      if (!baseParams) return {};

      for (const x in baseParams) {
        const y = x as keyof typeof baseParams;

        if (baseParams[y] === '') {
          delete baseParams[y];
        }
      }

      return baseParams;
    }
  })
    .then(_ => _.data as PaginatedTableResponseType<WaferbookByLotId>)
    .catch(err => console.log(err));
};

export const getInspectionReport = (
  params: ApiBase<
    ({ lot_id__in: number[] } | { wafer_id__in: number[] }) & {
      report_format: string;
    }
  >
) => {
  return apiCallBack({
    method: 'GET',
    url: `/api/v1/classif-ai/wafer-map/generate-inspection-report/`,
    responseType: 'blob',
    ...params,
    transformParams(baseParams) {
      const finalParam: {
        lot_id?: string;
        wafer_id__in?: string;
        report_format: string;
      } = { report_format: baseParams!.report_format };

      if (baseParams) {
        if ('lot_id__in' in baseParams) {
          finalParam.lot_id = baseParams.lot_id__in.join(',');
        }

        if ('wafer_id__in' in baseParams) {
          finalParam.wafer_id__in = baseParams.wafer_id__in.join(',');
        }
      }

      return finalParam;
    }
  }).then(response => {
    return handleFileDownload(response);
  });
};
