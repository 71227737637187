import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CommonButton from 'app/components/ReviewButton';
import { DATE_RANGE_KEYS } from 'app/constants/filters';
import { encodeURL } from 'app/utils/helpers';

import queryString from 'query-string';
import { useNavigate, useParams } from 'react-router-dom';

type ViewUniqueImagesButtonProps = { tagId: number };

export const ViewUniqueImagesButton = ({
  tagId
}: ViewUniqueImagesButtonProps) => {
  const { subscriptionId, packId } = useParams();

  const handleViewUniqueImageClick = () => {
    const params = queryString.stringify({
      contextual_filters: encodeURL({
        tags__in: tagId,
        date: DATE_RANGE_KEYS.ALL_DATE_RANGE,
        is_unique_image_view: true
      })
    });
    window.open(`/${subscriptionId}/${packId}/annotation/review?${params}&`);
  };

  return (
    <CommonButton
      text='View unique image'
      onClick={handleViewUniqueImageClick}
      variant='tertiary'
      icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
    />
  );
};
