import { string } from 'prop-types';
import { apiCallBack } from '../ApiMethods';
import { InferenceStatusParams, InferenceStatusResponse } from './types';

import { encodeURL, getDateFromParams, getDecodedURL } from 'app/utils/helpers';
import { errorToast } from 'app/utils/toast';

export const getWaferMapById: (params: ApiBase<{ id: number }>) => Promise<{
  organization_wafer_id: string;
}> = (params: ApiBase<{ id: number }>) => {
  const { id } = params.baseParams;

  return apiCallBack({
    method: 'GET',
    url: `/api/v1/classif-ai/wafer-map/${id}/`,
    ...params
  }).then(
    _ =>
      _.data as {
        organization_wafer_id: string;
      }
  );
};

export const getInferenceStatus = (params: ApiBase<InferenceStatusParams>) => {
  return apiCallBack<InferenceStatusParams, unknown, InferenceStatusResponse>({
    method: 'GET',
    url: `/api/v1/classif-ai/file-set-inference-queue/progress_status/`,
    ...params,
    transformParams: baseParams => {
      const {
        ml_model_id__in,
        useLocationParams = false,
        ...rest
      } = baseParams as unknown as object & {
        ml_model_id__in: number;
        useLocationParams?: boolean;
        upload_session_id__in: string;
      };

      return {
        file_set_filters: encodeURL(
          useLocationParams
            ? getDateFromParams(rest, undefined, true)
            : {
                upload_session_id__in: rest.upload_session_id__in
              }
        ),
        ml_model_id__in
      };
    }
  }).then(_ => _.data);
};

export const getEADefectsTableData: (
  params: ApiBase<{ wafer_id__in: number[]; file_set_filters?: string }>
) => Promise<{
  results: {
    id: number;
    meta_info: Record<string, string | number>;
    wafer_id: number;
    wafer_dot_id: number;
  }[];
} | void> = (
  params: ApiBase<{ wafer_id__in: number[]; file_set_filters?: string }>
) => {
  const { decodedContextual, decodedOther, ...rest } = getDecodedURL(
    window.location.search
  );
  const fileSetFilters = encodeURL(
    getDateFromParams(
      { ...decodedContextual, ...decodedOther, ...rest, ...params.baseParams },
      undefined,
      true
    )
  );
  params.baseParams.file_set_filters = fileSetFilters;
  return apiCallBack({
    method: 'GET',
    url: `/api/v1/classif-ai/file-set/meta-info/`,
    ...params
  })
    .then(
      _ =>
        _.data as {
          results: {
            id: number;
            meta_info: Record<string, string | number>;
            wafer_id: number;
            wafer_dot_id: number;
          }[];
        }
    )
    .catch(e => {
      errorToast(
        e?.response?.data?.message ||
          e?.response?.message ||
          e?.message ||
          'Something went wrong. Please try again.'
      );
    });
};
